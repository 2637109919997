<template>
    <div class="page_myBusinessManagement iphonex-pb">
        <headeBar title="企业账户管理" left-arrow @click-left="newAppBack" />
        <div class="page_content">
            <div class="sec1 pub_avg pub_flexmiddle">
                <div class>
                    <div class="company_name">
                        {{ companyAdmin ? companyAdmin.companyName : '--' }}
                    </div>
                    <div class="company_id">
                        <span class="company_id_label">ID</span>
                        {{ companyAdmin ? companyAdmin.companyId : '--' }}
                    </div>
                </div>
                <div class="invite" @click="getQRcode">邀请成员</div>
            </div>
            <div class="sec2 basediv">
                <div class="pub_full pub_flexmiddle titleline">
                    <div class="basetitle">账户概况</div>
                    <div class="freedeposit" v-if="depositStatus">免押金</div>
                    <div class="pub_onefull"></div>
                    <template v-if="depositStatus">
                        <div class="positright">
                            免押保证金：￥{{ companyAdmin.assureMoney }}
                        </div>
                        <van-icon
                            name="question-o"
                            size="14px"
                            color="#6C727A"
                            @click="showAssureMoney"
                        />
                    </template>
                </div>
                <div class="amount pub_full">
                    <div class="pub_onefull" @click="goBalance">
                        <div class="BebasNeueBold">
                            <span class="mn1">¥</span>
                            <span class="mn2">
                                {{
                                    companyAdmin ? companyAdmin.acountNow : '0'
                                }}
                            </span>
                        </div>
                        <div class="amount_title">团体余额</div>
                    </div>
                    <div class="pub_onefull" @click="goBill">
                        <div class="BebasNeueBold">
                            <span class="mn1">¥</span>
                            <span class="mn2">{{ useAccount }}</span>
                        </div>
                        <div class="amount_title">消费金额</div>
                    </div>
                </div>
                <div class="range">使用范围：{{ useStr }}</div>
                <div class="setrange" @click="jumpSet">设置成员使用范围</div>
            </div>
            <div class="basediv sec3">
                <div class="basetitle">常用功能</div>
                <div class="function">
                    <div
                        class="funcmodel"
                        v-for="(value, key) in funcList"
                        :key="key"
                        @click="toUrl(value.url)"
                    >
                        <div class="imgbox">
                            <img :src="value.img" />
                            <div class="funconer" v-if="value.count">
                                {{ value.count }}
                            </div>
                        </div>
                        <div class="funclabel">{{ value.title }}</div>
                    </div>
                </div>
            </div>
            <div class="basediv sec4" v-show="tab">
                <div class="tabs">
                    <span
                        class="tab"
                        :class="{ tabac: tab === key }"
                        @click="setTabIndex(value,key,index)"
                        v-show="value.list.length > 0"
                        v-for="(value, key,index) in tabbarList"
                        :key="key"
                    >
                        {{ value.title }}
                    </span>
                </div>
                <div class="voucher" v-show="tab === 'coupon'">
                    <div
                        class="vouchermodel pub_full pub_flexmiddle"
                        v-for="item in tabbarList.coupon.list"
                        :key="item.id"
                    >
                        <div class="voucherimg">
                            <div class="BebasNeueBold vouchervaluebox">
                                ¥
                                <span class="vouchervalue">
                                    {{ item.discount }}
                                </span>
                            </div>
                        </div>
                        <div class="pub_onefull voucherinfobox">
                            <div class="vouchername ellipsis">
                                {{ item.voucherName }}
                            </div>
                            <div class="voucherinfo">
                                剩余
                                <span class="mc num">{{
                                    item.voucherNum
                                }}</span>
                                张
                            </div>
                        </div>
                        <div
                            class="voucherstate"
                            :class="{
                                mc: item.status === 1 && item.voucherNum !== 0,
                            }"
                        >
                            {{
                                item.status === 1 && item.voucherNum !== 0
                                    ? '启用中'
                                    : '已停用'
                            }}
                        </div>
                        <van-switch
                            :value="item.status === 1 && item.voucherNum !== 0"
                            :disabled="item.voucherNum === 0"
                            active-color="#FFDE00"
                            inactive-color="#E9E9EA"
                            @change="changeStatus(item)"
                            class="zswitch"
                        />
                    </div>
                    <div class="pub_avg">
                        <div
                            class="basesub"
                            @click="
                                toUrl(
                                    '/appWeb/enterprise/entersList.html?type=1'
                                )
                            "
                        >
                            用券明细
                        </div>
                        <div class="basesub" @click="goAct">发起活动</div>
                    </div>
                </div>
                <!--团课信息-->
                <div class="lessons" v-show="tab === 'lesson'">
                    <div
                        class="model pub_full pub_flexmiddle"
                        v-for="item in tabbarList.lesson.list"
                        :key="item.id"
                    >
                        <div class="modelimg">
                            <img
                                :src="$getImgUrl(item.image)"
                                v-if="item.image"
                            />
                            <div class="modelconer">
                                可容纳{{ item.maximum }}人
                            </div>
                        </div>
                        <div class="pub_onefull modelcontent">
                            <div class="modelname ellipsis">
                                {{ item.name }}
                            </div>
                            <div class="modelinfo">
                                <!-- 12-09 周四 11:30-12:30 -->
                                {{ item.date }}
                            </div>
                            <div class="modelinfo">
                                {{ item.teacher }}｜{{ item.venueName }}
                            </div>
                            <div class="modelprice">
                                <span class="lp1">包场价：</span>
                                <span class="lp2 BebasNeueBold">¥</span>
                                <span class="lp3 BebasNeueBold">{{
                                    item.price
                                }}</span>
                            </div>
                        </div>
                        <div
                            class="modelsub stateBuy"
                            v-if="item.status == '1'"
                            @click="buy(item)"
                        >
                            购买
                        </div>
                        <div
                            class="modelsub stateApportion"
                            v-else-if="item.manageStatus == '2'"
                            @click="fenpei(item)"
                        >
                            分配
                        </div>
                        <div
                            class="modelsub stateExpired"
                            v-else-if="item.manageStatus == '3'"
                        >
                            已结课
                        </div>
                        <div
                            class="modelsub stateExpired"
                            v-else-if="item.manageStatus == '4'"
                        >
                            已过期
                        </div>
                    </div>
                </div>
                <!--私教信息-->
                <div class="coach" v-show="tab === 'coach'">
                    <div
                        class="model pub_full pub_flexmiddle"
                        v-for="item in tabbarList.coach.list"
                        :key="item.id"
                    >
                        <div class="modelimg">
                            <img
                                :src="$getImgUrl(item.image)"
                                v-if="item.image"
                            />
                        </div>
                        <div class="pub_onefull modelcontent">
                            <div class="modelname ellipsis">
                                {{ item.name }}
                            </div>
                            <div class="modelinfo">
                                <!-- 12-09 周四 11:30-12:30 -->
                                {{ item.date }}
                            </div>
                            <div class="modelinfo modelcocah ellipsis">
                                <span> {{ item.teacher }}</span>
                                <span class="modellevel">PT{{item.pt}}</span>
                                <span>｜</span>
                                <span>{{ item.venueName }}</span>
                            </div>
                            <div class="modelprice">
                                <span class="lp1">价格：</span>
                                <span class="lp2 BebasNeueBold">¥</span>
                                <span class="lp3 BebasNeueBold">{{
                                    item.price
                                }}</span>
                            </div>
                        </div>
                        <div
                            class="modelsub stateBuy"
                            v-if="item.status == '1'"
                            @click="buy(item)"
                        >
                            购买
                        </div>
                        <div
                            class="modelsub stateApportion"
                            v-else-if="item.status == '2'||item.status == '3'"
                            @click="fenpei(item)"
                        >
                            {{item.status == '2'?'分配':'已分配'}}
                        </div>
                        <div
                            class="modelsub stateExpired"
                            v-else-if="item.status == '5'"
                        >
                            已结课
                        </div>
                        <div
                            class="modelsub stateExpired"
                            v-else-if="item.status == '4'"
                        >
                            已过期
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <van-action-sheet
            v-model="helpShow"
            :actions="actions"
            cancel-text="取消"
            close-on-click-action
            @select="choseAct"
        ></van-action-sheet>
        <van-action-sheet
            v-model="callShow"
            :actions="phoneActions"
            cancel-text="取消"
            close-on-click-action
            @select="choseActPhone"
        ></van-action-sheet>
        <van-popup
            v-model="showConfirm"
            position="bottom"
            safe-area-inset-bottom
            class="confirm"
        >
            <van-icon
                name="cross"
                class="close_confirm"
                @click="showConfirm = false"
            />
            <div class="orderinfo" >
                <div class="confirm_title">请确认购买信息</div>
                <div class="confirm_courseName">{{ item.name }}</div>
                <div class="confirm_small bd">{{ item.date }}  </div>
                <div class="confirm_small">
                    {{ item.teacher }} <span class="confirm_pt" v-if="tab=='coach'" >PT{{item.pt}}</span>｜{{ item.venueName }}
                </div>
                <div class="confirm_small" v-if="tab=='lesson'" >
                    最多可容纳 {{ item.maximum }} 人参与
                </div>
            </div>
            <div class="pub_full pub_flexmiddle vo">
                <van-icon name="volume-o" class="ic" />
                将使用您的团体余额支付，请确保余额充足
            </div>
            <div class="totalbar">
                <div class="totalbar-content">
                    <div class="totalbar-left">
                        <span class="paytotal">
                            <span class="paytotal-logo BebasNeueBold">¥</span>
                            <span class="paytotal-value BebasNeueBold">{{
                                tofx2(item.price)
                            }}</span>
                        </span>
                    </div>
                    <div class="paysub" @click="confirm">确认购买</div>
                </div>
            </div>
        </van-popup>
        <courseArrangement ref="courseArrangement" />
        <van-popup
            v-model="showSuccess"
            position="bottom"
            safe-area-inset-bottom
            class="paysuccess"
        >
            <van-icon
                name="cross"
                class="pay_close"
                color="#9AA1A9"
                @click="showSuccess = false"
            />
            <div class="paybox">
                <img
                    src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/ok.png"
                    class="paylogo"
                />
                <div class="paylabel">支付成功</div>
            </div>
            <div class="paysubbox van-hairline--top">
                <div class="paysub" @click="choose" >选择参与人</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { newAppBack, initBack, defaultApp } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import courseArrangement from './components/courseArrangement';
export default {
    components: {
        headeBar,
        courseArrangement,
    },
    mixins: [userMixin],
    data() {
        return {
            tabbarList:{
                coupon:{
                    title: '申请优惠券',
                    list: [],
                    method: this.getGroupCoupon,
                },
                lesson:{
                    title: '企业团课',
                    list: [],
                    method: this.getLession,
                },
                coach:{
                    title: '企业私教',
                    list: [],
                    method: this.getCoach,
                },
            },
            funcList: {
                newMembers: {
                    img: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/new.png',
                    title: '新成员审批',
                    count: 0,
                    url: '/appWeb/enterprise/userApply.html',
                },
                voucher: {
                    img: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/voucher.png',
                    title: '优惠券审批',
                    count: 0,
                    url: '/appWeb/enterprise/couponsApply.html',
                },
                card: {
                    img: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/card.png',
                    title: '卡券兑换',
                    count: 0,
                    url: '/appWeb/enterprise/voucherExchange.html?v=1.5',
                },
                members: {
                    img: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/members.png',
                    title: '成员管理',
                    count: 0,
                    url: '/appWeb/enterprise/userManagement.html',
                },
                roles: {
                    img: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/roles.png',
                    title: '角色管理',
                    count: 0,
                    url: '/appWeb/enterprise/roleConfig.html',
                },
                transfer: {
                    img: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/transfer.png',
                    title: '转让管理员',
                    count: 0,
                    url: '/appWeb/enterprise/changeAdmin.html',
                },
            },
            useStr: '',
            tab: '',
            useAccount: 0,
            depositStatus: false,
            assureMoney: 0,
            depositMoney: 0,
            helpShow: false,
            callShow: false,
            actions: [{ name: '帮助中心' }, { name: '联系客服' }],
            phoneActions: [],
            showConfirm: false,
            item: {}, //购买的信息
            showSuccess: false,
        };
    },
    methods: {
        newAppBack,
        tofx2(val){
            return isNaN(val)?'--':parseFloat(val).toFixed(2);
        },
        toUrl(url) {
            defaultApp();
            const bs=this.isTestEnvironment? 'http://wxtest.chaolu.com.cn':'http://wx.chaolu.com.cn';
            if(url.indexOf('?')>-1){
                location.href = bs + url+'&time='+new Date().getTime();
            }else{
                location.href = bs + url+'?time='+new Date().getTime();
            }
        },
        goAct() {
            if (this.companyAdmin.companyStatus === 1) {
                this.toUrl('/appWeb/enterprise/entersRecord.html');
            } else {
                this.$toast('您的团体账户已失效，无法发起活动');
            }
        },
        getQRcode() {
            const companyId = this.companyAdmin.companyId;
            const companyName = this.companyAdmin.companyName;
            const staffId = this.companyUser.staffId;
            const url =
                '/appWeb/enterprise/inviteQRcode.html?companyId=' +
                companyId +
                '&companyName=' +
                companyName +
                '&staffId=' +
                staffId;
            this.toUrl(url);
        },
        goBalance() {
            this.toUrl('/appWeb/enterprise/balanceDetail.html');
        },
        jumpSet() {
            if (this.companyAdmin.companyStatus === 1) {
                this.toUrl('/appWeb/enterprise/acountConfig.html');
            } else {
                this.$toast('您的团体账户已失效，无法进行设置');
            }
        },
        goBill() {
            this.toUrl('/appWeb/enterprise/useBillDetails.html');
        },
        showAssureMoney() {
            const ale =
                '亲爱的企业用户，根据协议，您的余额低于' +
                this.depositMoney +
                '元时将暂时失去免押特权，低于' +
                this.assureMoney +
                '元时账户将被暂时冻结，我们会提前给您通知，祝您健身愉快。';
            this.$dialog
                .alert({
                    message: ale,
                })
                .then(() => {
                    // on close
                });
        },
        showca(item) {
            this.$refs.courseArrangement.setaclist(item);
        },
        setTabIndex(value,key,index){
            this.tab=key;
            value.method().then(()=>{console.log(value.list.length)
                if(value.list.length==0){
                    this.$toast('暂无数据');
                    this.setDataIndex();
                }
            });
        },
        getGroupCoupon() {
            //获取优惠券
            return new Promise((resolve, reject) => {
                this.$axios
                    .post(this.baseURL + '/coo/getGroupList', {
                        companyId: this.companyAdmin.companyId,
                        userId: this.userId,
                        token: this.token,
                    })
                    .then((res) => {
                        if (res.code == '1') {
                            this.tabbarList.coupon.list = res.data.filter(
                                (x) => x.isDue === 0
                            );
                            resolve(this.tabbarList.coupon.list);
                        }
                    });
            });
        },
        getLession() {
            //获取课程
            return new Promise((resolve, reject) => {
                this.$axios
                    .post(this.baseURLApp + '/companyLesson/manageList', {
                        companyId: this.companyAdmin.companyId,
                        userId: this.userId,
                        token: this.token,
                    })
                    .then((res) => {
                        // console.log(res);
                        if (res.code == '1') {
                            this.tabbarList.lesson.list = res.data.map(d=>{
                                return{
                                    ...d,
                                    name:d.courseName,
                                    image:d.courseMainimage,
                                    date:d.date,
                                    venueName:d.venueName,
                                    price:d.companyPrice,
                                    status:d.manageStatus,
                                    teacher:d.teacherName,
                                    id:d.lessonId,
                                    mouldId:d.lessonId
                                }
                            });
                            resolve(this.tabbarList.lesson.list);
                        }else{
                            resolve([]);
                        }
                    }).catch(()=>{
                        resolve([]);
                    });
            });
        },
        getCoach() {
            //获取企业私教
            return new Promise((resolve, reject) => {
                this.$axios
                    .post(
                        this.baseURLApp +
                            '/per/app/clpercoursearra/enterpriseCourseList',
                        {
                            companyId: this.companyAdmin.companyId,
                            userId: this.userId,
                            token: this.token,
                        }
                    )
                    .then((res) => {
                        // console.log(res);
                        if (res.code == '1') {
                            this.tabbarList.coach.list = res.data.map(d=>{
                                return{
                                    ...d,
                                    name:d.projectName,
                                    image:d.mainImage,
                                    date:d.time,
                                    venueName:d.venueName,
                                    price:d.price,
                                    status:d.status,
                                    teacher:d.coachName,
                                    id:d.perCourseArraId,
                                    mouldId:d.projectId
                                }
                            });
                            resolve(this.tabbarList.coach.list);
                        }else{
                            resolve([]);
                        }
                    }).catch(()=>{
                        resolve([]);
                    });
            });
        },

        async changeStatus(item) {
            //开启关闭优惠券
            if (this.companyAdmin.companyStatus === 1) {
                var data = await this.$axios.post(
                    this.baseURL + '/coo/changeGroupStatus',
                    {
                        id: item.id,
                        userId: this.userId,
                        token: this.token,
                        status: item.status === 1 ? 0 : 1,
                    }
                );
                this.$toast(data.msg);
                if (data.code === '1') {
                    item.status = item.status === 1 ? 0 : 1;
                }
            } else {
                this.$toast('您的团体账户已失效，无法进行设置');
            }
        },
        async getConfig() {
            //获取余额配置
            var data = await this.$axios.post(
                this.baseURL + '/coo/getGroupGiftCardInfo',
                {
                    accountId: this.companyAdmin.accountId,
                    companyId: this.companyAdmin.companyId,
                    userId: this.userId,
                    token: this.token,
                }
            );
            if (data.code === '1') {
                this.showFlag = data.data.showFlag === '1';
                this.useTypesList = data.data.useTypes;
                let useStr = '当前可用于';
                for (var i = 0; i < this.useTypesList.length; i++) {
                    var item = this.useTypesList[i];
                    if (item.useTypeStatus) {
                        useStr += item.useTypeName + '、';
                    }
                }
                this.useStr = useStr.substring(0, useStr.length - 1);
            } else {
                this.$toast(data.msg);
            }
        },
        async getUse() {
            if (this.companyAdmin.companyStatus === 1) {
                const myDate = new Date();
                const month = myDate.getMonth() + 1;
                var param = {
                    accountId: this.companyAdmin.accountId,
                    date: myDate.getFullYear() + '-' + ('0' + month).slice(-2),
                    userId: this.userId,
                    token: this.token,
                };

                var data = await this.$axios.post(
                    this.baseURLApp + '/coo/accountOrder/getUseAmount',
                    param
                );
                if (data.code === '1') {
                    this.useAccount = data.data.useAmount;
                }
            } else {
                this.$toast('您的团体账户已失效，无法进行设置');
            }
        },
        choseAct(act, index) {
            console.log(act, index);
            if (index === 0) {
                this.toUrl('/appWeb/enterprise/adminHelp.html');
            } else if (index === 1) {
                this.callShow = true;
            }
        },
        choseActPhone() {
            window.location.href = 'tel://' + this.phoneActions[0].name;
        },
        fenpei(item) {
            if(item.openFlag==1){
                this.$toast('已经开班了');
                return;
            }
            this.$router.push(
                '/enterprise/staff-list?id=' + item.id+'&type='+this.tab
            );
        },
        choose(){
            this.showSuccess=false;
            this.fenpei(this.item);
        },
        buy(item) {
            this.item = item;
            this.showConfirm = true;
        },
        confirm() {
            const item = this.item;
            this.showConfirm = false;
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            });
            const type=this.tab=='lesson'?'1':this.tab=='coach'?'8':null;
            if(!type){
                this.$toast('参数有误');
            }
            let ps = {
                userId: this.userId,
                token: this.token,
                openId: '',
                mouldId: item.mouldId,
                type: type,
                discountId: '', //优惠券
                isBalance: '', //余额
                isDuration: '', //鹿分
                cdKeyId: '',
                totalAmount: '0',
                whetherPrivacy: '0',
                wxPayType: 'applet',
                isApp: '1',
                extendsType: '5',
                extendsJson: JSON.stringify({
                    appVersion: '2.0.90',
                }),
                userType: '3',
                companyAccountId: this.companyAdmin.accountId,
            };
            if(this.tab=='coach'){
                ps={
                    ...ps,
                    trainId:item.id,
                    coachId:item.coachId,
                    venueId:item.venueId
                }
            }
            console.log(ps);
            this.$axios
                .post(this.baseURLApp + '/wechat/createWechatOrder', ps)
                .then((res) => {
                    if (res.code == '1') {
                        if(this.tab=='lesson'){
                            this.$toast('购买成功');
                        }else{
                            this.$toast.clear();
                            this.showSuccess=true;
                        }
                        this.getAll();
                    } else {
                        this.$toast('失败');
                    }
                })
                .catch(() => {});
        },
        async getAll() {
            await this.$getCompanyAdmin(true); //获取企业信息，重新拿不获取缓存
            if (this.userId && this.token && this.companyAdmin) {
                //获取到了全部信息可以开始写业务
                const data = this.companyAdmin;
                this.depositStatus = data.depositStatus === '0' ? false : true;
                this.assureMoney = data.assureMoney;
                this.depositMoney = data.depositMoney;
                this.funcList.newMembers.count = data.toBeExamineNum;
                this.phoneActions = [
                    {
                        name:
                            data.cityName === '广州'
                                ? '15080008837'
                                : '13911811244',
                    },
                ];
                this.getConfig();
                this.getUse();
                this.getBar();
            } else {
                console.log('参数有误');
            }
        },
        getBar() {
            const doList = [];
            const tl = this.tabbarList;
            for (let i in tl) {
                doList.push(tl[i].method && tl[i].method());
            }

            Promise.all(doList).then((res) => {
                if (!this.tab) {
                    this.setDataIndex();
                }else{
                    if(this.tabbarList[this.tab].list.length==0){
                        this.setDataIndex();
                    }
                }
            })
        },
        setDataIndex(){
            for(let i in this.tabbarList){
                if(this.tabbarList[i].list.length>0){
                    this.tab=i;
                    return;
                }
            }
            this.tab='';
        },
        async init() {
            this.showSuccess=false;
            await this.$getAllInfo(); //获取userId,token
            this.getAll();
        },
        setScroll(from) {
            this.$nextTick(() => {
                if (from.path == '/enterprise/index') {
                    //首页
                    this.$el.scrollTop = 0;
                } else {
                    this.$el.scrollTop = this.scrolltop || 0;
                }
            });
        },
    },
    created() {
        initBack(); //劫持后退
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.init();
            vm.setScroll(from);
        });
    },
    beforeRouteLeave(to, from, next) {
        this.scrolltop = this.$el.scrollTop;
        next();
    },
};
</script>

<style lang="less" scoped >
.page_myBusinessManagement {
    --main-color: #242831;
    background: #f5f5f5;
    height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    .page_content {
        background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/background.png)
            no-repeat;
        background-size: 100% auto;
        padding: 0 24px 1px;
        .mc {
            color: var(--main-color) !important;
        }
        .basediv {
            background: #fff;
            border-radius: 8px;
            padding: 24px;
            margin-bottom: 24px;
        }
        .basetitle {
            color: var(--main-color);
            font-size: 28px;
            font-weight: bold;
        }
        .sec1 {
            color: #fff;
            padding: 48px 0;
            .company_name {
                font-size: 32px;
                font-weight: bold;
                margin-bottom: 12px;
            }
            .company_id {
                font-size: 22px;
                .company_id_label {
                    font-weight: bold;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
            .invite {
                background: var(--main-color);
                width: 152px;
                line-height: 60px;
                border-radius: 8px;
                font-size: 24px;
                text-align: center;
                font-weight: bold;
            }
        }
        .sec2 {
            .titleline {
                line-height: 1;
                overflow: visible;
            }
            .freedeposit {
                background: #3c454e;
                font-size: 22px;
                line-height: 36px;
                color: #fff;
                padding: 0 8px;
                margin-left: 16px;
                border-radius: 4px;
                font-weight: bold;
            }
            .positright {
                color: #6c727a;
                font-size: 22px;
                margin-right: 8px;
            }
            .amount {
                text-align: center;
                margin: 60px 0 44px;
                .mn1 {
                    font-size: 32px;
                    margin-right: 4px;
                }
                .mn2 {
                    font-size: 56px;
                    display: inline-block;
                }
                .amount_title {
                    font-size: 22px;
                    color: #9aa1a9;
                }
            }
            .range {
                color: #267dff;
                font-size: 20px;
                text-align: center;
            }
            .setrange {
                line-height: 88px;
                color: #267dff;
                text-align: center;
                border-radius: 8px;
                margin-top: 32px;
                font-size: 28px;
                background: rgba(38, 125, 255, 0.1);
                font-weight: bold;
            }
        }
        .sec3 {
            .function {
                margin-top: 8px;
                display: flex;
                flex-wrap: wrap;
                .funcmodel {
                    margin-top: 24px;
                    margin-bottom: 24px;
                    width: 28%;
                    text-align: center;
                    &:nth-child(3n + 2) {
                        margin-left: 8%;
                        margin-right: 8%;
                    }
                    .imgbox {
                        width: 80px;
                        height: 80px;
                        margin: 0 auto;
                        position: relative;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        .funconer {
                            border: 1px solid #fff;
                            color: #fff;
                            font-size: 22px;
                            padding: 0 12px;
                            min-width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            right: -16px;
                            top: -20px;
                            background: #ed5c42;
                            border-radius: 20px;
                            box-sizing: border-box;
                        }
                    }
                    .funclabel {
                        color: var(--main-color);
                        font-size: 20px;
                        font-weight: bold;
                        margin-top: 16px;
                    }
                }
            }
        }
        .sec4 {
            .tabs {
                margin-bottom: 32px;
                .tab {
                    color: #9aa1a9;
                    font-size: 24px;
                    font-weight: bold;
                    // width: 170px;
                    display: inline-block;
                    padding-bottom: 18px;
                    box-sizing: border-box;
                    position: relative;
                    margin-right: 32px;
                }
                .tabac {
                    color: var(--main-color);
                    font-size: 28px;
                    &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        width: 48px;
                        height: 6px;
                        border-radius: 4px;
                        transform: translateX(-50%);
                        background: #267dff;
                    }
                }
            }
            .vouchermodel {
                margin-bottom: 48px;
                .voucherimg {
                    width: 160px;
                    height: 128px;
                    background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/voucherbg.png);
                    background-size: cover;
                    margin-right: 24px;
                    font-size: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    .vouchervaluebox {
                        height: 52px;
                        line-height: 1;
                        overflow: visible;
                        .vouchervalue {
                            font-size: 64px;
                        }
                    }
                }
                .voucherinfobox {
                    overflow: hidden;
                    .vouchername {
                        color: var(--main-color);
                        font-weight: bold;
                        font-size: 28px;
                    }
                    .voucherinfo {
                        margin-top: 14px;
                        font-size: 20px;
                        color: #6c727a;
                        .num {
                            font-weight: bold;
                        }
                    }
                }
                .voucherstate {
                    color: #6c727a;
                    font-size: 20px;
                    margin: 0 16px;
                }
                .zswitch {
                    width: 80px;
                    height: 48px;
                    box-sizing: border-box;
                    border: none;
                    /deep/ .van-switch__node {
                        top: 4px;
                        left: 4px;
                        width: 40px;
                        height: 40px;
                        box-shadow: none;
                    }
                }
                /deep/ .van-switch--on .van-switch__node {
                    transform: translateX(32px);
                }
            }
            .basesub {
                background: rgba(38, 125, 255, 0.1);
                border-radius: 8px;
                text-align: center;
                font-size: 28px;
                color: #267dff;
                line-height: 88px;
                width: 314px;
                font-weight: bold;
            }
            .model {
                margin-bottom: 48px;
                .modelimg {
                    background: #f7f7f7;
                    width: 176px;
                    height: 176px;
                    border-radius: 8px;
                    overflow: hidden;
                    position: relative;
                    margin-right: 24px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .modelconer {
                        position: absolute;
                        left: 0;
                        top: 0;
                        padding: 0 8px;
                        background: rgba(0, 0, 0, 0.5);
                        color: #fff;
                        font-size: 18px;
                        line-height: 32px;
                        border-radius: 8px;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: 0;
                        font-weight: bold;
                    }
                    .modelconer_bottom {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        background: rgba(0, 0, 0, 0.5);
                        line-height: 32px;
                        color: #fff;
                        font-weight: bold;
                        text-align: center;
                        font-size: 18px;
                    }
                }
                .modelcontent {
                    overflow: hidden;
                }
                .modelname {
                    color: var(--main-color);
                    font-weight: bold;
                    font-size: 28px;
                    margin-bottom: 8px;
                }
                .modelinfo {
                    color: #6c727a;
                    font-size: 20px;
                    margin-top: 12px;
                    .info2 {
                        margin: 0 4px 0 16px;
                    }
                }

                .modellevel {
                    position: relative;
                    top: 0;
                    padding: 0 4px;
                    border-radius: 2px;
                    border: 0.5PX solid #267dff;
                    color: #267dff;
                    font-size: 16px;
                    margin: 0 4px;
                    height: 20px;
                    line-height: 20px;
                    display: inline-block;
                    box-sizing: border-box;
                }
                .modelprice {
                    color: var(--main-color);
                    margin-top: 12px;
                    .lp1 {
                        font-size: 20px;
                    }
                    .lp2 {
                        font-size: 24px;
                        margin-right: 4px;
                    }
                    .lp3 {
                        font-size: 36px;
                        position: relative;
                        top: 2px;
                    }
                    .lp4 {
                        margin-left: 4px;
                        font-size: 20px;
                    }
                }
                .modelsub {
                    width: 104px;
                    padding: 12px 0;
                    border-radius: 8px;
                    text-align: center;
                    font-weight: bold;
                    font-size: 24px;
                    margin-left: 24px;
                    .fpnum{
                        font-size: 16px;
                    }
                }
                .stateBuy {
                    background: #ffde00;
                    color: var(--main-color);
                }
                .stateApportion {
                    background: rgba(38, 125, 255, 0.1);
                    color: #267dff;
                }
                .stateExpired {
                    background: #b2b4b7;
                    color: #fff;
                    // pointer-events: none;
                }
            }
        }
    }
    .buyitem {
        font-size: 26px;
        padding: 30px;
    }
    .confirm {
        .close_confirm {
            position: absolute;
            right: 36px;
            top: 36px;
            font-size: 38px;
            color: #9aa1a9;
        }
        .orderinfo {
            padding: 48px 32px 0;
            height: 424px;
            box-sizing: border-box;
            .confirm_title {
                font-size: 36px;
                font-weight: bold;
                color: var(--main-color);
                margin-bottom: 48px;
            }
            .confirm_courseName {
                font-size: 32px;
                font-weight: bold;
                color: var(--main-color);
            }
            .confirm_small {
                font-size: 24px;
                color: #6c727a;
                margin-top: 16px;
            }
            .bd {
                font-weight: bold;
                color: var(--main-color);
            }
            .confirm_pt{
                position: relative;
                top: -1px;
                padding: 0 6px;
                border-radius: 2px;
                border: 0.5PX solid #267dff;
                color: #267dff;
                font-size: 18px;
                margin: 0 4px;
                height: 24px;
                line-height: 26px;
                box-sizing: border-box;
            }
        }
        .vo {
            background: #f4f4f8;
            font-size: 24px;
            height: 72px;
            line-height: 1;
            color: #6C727A;
            .ic {
                margin: 0 16px 0 32px;
                color: #6c727a;
                font-size: 28px;
                font-weight: bold;
            }
        }
        .totalbar {
            left: 0;
            width: 100%;
            background: #fff;
            z-index: 99;
            .totalbar-content {
                padding: 0 32px;
                display: flex;
                justify-content: space-between;
                color: var(--main-color);
                align-items: center;
                height: 144px;
            }
            .totalbar-left {
                font-size: 24px;
                line-height: 1;
            }
            .paytotal {
                color: #ed5c42;
            }
            .paytotal-logo {
                font-size: 28px;
            }
            .paytotal-value {
                font-size: 56px;
            }
            .pay-num{
                color: var(--main-color);
                font-size: 24px;
                margin-left: 8px;
            }
            .paysub {
                background: #ffde00;
                border-radius: 8px;
                text-align: center;
                line-height: 96px;
                width: 360px;
                font-weight: bold;
            }
        }
    }
    .paysuccess {
        .pay_close {
            font-size: 40px;
            position: absolute;
            top: 32px;
            right: 32px;
        }
        .paybox {
            text-align: center;
            padding: 138px 0;
            .paylogo {
                width: 144px;
                height: 144px;
            }
            .paylabel {
                color: var(--main-color);
                font-size: 32px;
                margin-top: 32px;
            }
        }
        .paysubbox {
            background: #fff;
            width: 100%;
            padding: 24px 32px;
            box-sizing: border-box;
            .paysub {
                background: #ffde00;
                border-radius: 8px;
                font-weight: bold;
                text-align: center;
                line-height: 96px;
                color: #242831;
                font-size: 32px;
            }
        }
    }
}
</style>
