<template>
  <div class="page-rooter-fff">
    <nav-bar headerTitle="企业用户首页" ref="head" :showBack="true" :showTxt="'···'">
      <div @click="show = true" class="height-btn"><van-icon size="7vw" name="ellipsis" /></div>
    </nav-bar>
    <app-page class="container">
        <div class="top-box">
          <div class="user-info">
            <div>
              <img :src="headImg"/>
              <div>
                <div class="user-name">{{ name }} <span>{{ identity }}</span></div>
                <div class="user-phone"><span>手机</span>{{ phone }}</div>
              </div>
            </div>
            <div @click="inviteFun()" class="invite-btn">邀请同事</div>
          </div>
          <div class="account" v-if="(limitList.length || singleLimitFlag) && status===1 && identity==='已认证'">
            <div class="title">
              <div>账户概况</div>
              <div v-if="singleLimitFlag">单笔订单不得超过{{ singleLimit }}元</div>
            </div>
            <div class="account-info" v-if="limitList.length">
              <div v-for="(item, index) in limitList" :key="index" :style="limitList.length === 1? 'flex-grow: 1':limitList.length === 2?'width: 50%':''">
                <div><span v-if="item.limitName.indexOf('笔数') < 0">¥</span>{{ item.used }}</div>
                <div>{{ item.usedName }}</div>
                <div>{{ item.limitAllName }}</div>
              </div>
            </div>
          </div>

          <div class="account enterprise">
            <div class="title">
              <div>所属企业 <span  v-if="depositStatus && identity==='已认证'">免押金</span></div>
              <div>管理员：{{ adminName }}</div>
            </div>
            <div class="enterprise-info">
              <div>{{ companyName }}</div>
              <div>ID:{{ companyId }}</div>
            </div>

            <div @click="goAdminIndex()" v-if="isAdmin===1" class="enterprise-btn">进入管理企业</div>
          </div>
        </div>
        <div v-if="status===1 && identity==='已认证' && (this.lessonList.length || this.groupCouponList.length)" class="tabs-box">
        <div class="tabs-nav">
          <div v-if="groupCouponList.length > 0" @click="active = 1" :class="{active: active === 1}">申请优惠券</div>
          <div v-if="lessonList.length > 0" @click="active = 2" :class="{active: active === 2}">企业团课</div>
        </div>
        <div class="voucher" v-if="active === 1">
          <div class="voucher-item" v-for="(item, index) in groupCouponList" :key="index">
            <div class="item-l">
              <div class="item-bg"><div><span>¥</span>{{ item.discount }}</div></div>
              <div class="voucher-name">
                <div>{{ item.voucherName }}</div>
                <div>剩余 <span>{{ item.voucherNum }}</span> 张</div>
              </div>
            </div>
            <div class="item-r">
              <van-stepper :min="0" theme="round" :max="item.voucherNum > item.voucherMax? item.voucherMax: item.voucherNum" v-model="item.buyNum" />
            </div>
          </div>
          <div @click="applyFun()" v-if="isApply === '0'" class="apply-btn">提交申请</div>
          <div v-else class="apply-btn" style="opacity: .5">申请待审核</div>
        </div>
        <div class="course" v-if="active === 2">
          <div class="course-item" v-for="(item, index) in lessonList" :key="index">
            <div class="item-l">
              <div class="course-pic"><img :src="item.courseMainimage" alt=""></div>
              <div class="course-name">
                <div style="max-width: 49vw" class="ellipsis">{{ item.courseName }}</div>
                <div>{{ item.date }}</div>
                <div>{{ item.teacherName }}｜{{ item.venueName }}</div>
              </div>
            </div>
            <div v-if="item.userStatus === '1'" @click="companyCourseDetail(item.lessonId)" class="item-r">约课</div>
            <div v-else-if="item.userStatus === '2'" @click="tipsFull()" class="item-r full">满员</div>
            <div v-else-if="item.userStatus === '3'" class="item-r end">结课</div>
          </div>
        </div>
      </div>

        <div class="freeze" v-if="identity!=='认证失败' && (companyStatus==='2' || status!==1)">
          <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/freeze.png" alt="">
          <p>账户已被冻结，如有疑问请联系您的企业管理员</p>
        </div>
    </app-page>

    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @cancel="show = false"
      @select="logOutTeam"
    />

  </div>
</template>

<script>
import appMixin from '@/mixin/appMixin'
import navBar from '@/components/nav-bar/nav-bar'
import appPage from '@/common/components/appPage'
import {
  getUserIdAndToken,
  companyCourseDetail,
  initBack,
  defaultApp
} from '@/lib/appMethod'
import { appHeaderAdapt } from '@/lib/utils'
import {viewDidAppear} from "../../lib/appMethod";


export default {
  data() {
    return {
      show: false,
      actions: [{ name: '退出团体' }],
      param: {userId: '', token: ''},
      headImg:'',
      phone:'',
      name:'',
      adminName:'',
      identity:'待认证',
      employeeNo:'',
      companyName:'',
      companyId:'',
      actList:[], //活动券数组
      groupCouponList:[], //申请优惠券裂变
      isApply:'', //是否申请
      editShow:false, // 名字编辑
      changeName:'',
      logOutShow:false,
      isAdmin:0,
      status:1, //员工是否冻结1可用，2冻结
      companyStatus:1, //1有效，2无效
      balanceStatus:1,
      depositStatus:false, //	团体是否免押金（1是 0否）
      limitList:[], //限额数组
      lessonList:[], // 用户企业团课列表
      singleLimitFlag:false,
      singleLimit:0,
      limitShow:false,
      active: 1,
      value: 1
    };
  },
  mixins: [appMixin],
  components: {
    navBar,
    appPage,
  },
  created() {
    this.baseURLCopy = 'http://wx.chaolu.com.cn'
    this.$store.commit('setOpenByApp',true);//hack 不应该在这里设置 此项目移植特例
    initBack(); //劫持后退
    this.load();
    viewDidAppear()
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        if(this.param.userId && this.param.token){
          this.getUserMessage();
        }
      }
    })
  },
  methods: {
    async load() { // 页面初次加载
      try {
        this.param = await getUserIdAndToken(this.baseURLApp);
      }catch (e){
        this.param = {
          "userId": 19553076,
          "token": "Isu3fth4lOFpl75yzvQ"
        }
        console.log(this.param)
        console.log(e)
      }
      this.getUserMessage()
    },
    inviteFun(){
      defaultApp();
      window.location.href = `${this.baseURLCopy}/appWeb/enterprise/inviteQRcode.html?companyId=${this.companyId}&companyName=${this.companyName}&staffId=${this.staffId}&timeStrap=${new Date().getTime()}`
    },
    goAdminIndex(){
      this.$router.push('/enterprise/admin');
      // window.location.href = `${this.locationUrl}/#/myBusinessManagement/index?timeStrap=${new Date().getTime()}`
      // window.location.href = `http://192.168.0.41:8888/#/myBusinessManagement/index?timeStrap=${new Date().getTime()}`
    },
    getGroupCoupon(){
      //获取公司优惠券信息
      this.$axios.post(`${this.baseURL}/coo/getVoucherGroup`, this.param).then(data => {
        this.groupCouponList = data.data.data || []
        this.active = this.groupCouponList.length > 0 ? 1 : 2
        this.isApply = data.data.isApply
      })
    },
    companyCourseDetail(mouldId) {
      companyCourseDetail(mouldId)
    },
    tipsFull() {
      this.$toast('人数已约满')
    },
    applyFun(){
      //提交申请公司优惠券
      let applyArr=[]
      this.groupCouponList.forEach(item => {
        if(item.buyNum>0){
          applyArr.push({
            applyPhone:this.phone,
            applyStaffId:this.staffId,
            voucherGroupId:item.id,
            voucherId:item.voucherId,
            applyNum:item.buyNum,
            companyId:this.companyId,
            userId:this.param.userId,
            token:this.param.token,
          })
        }
      })
      if(applyArr.length <= 0){
        this.$toast('请先选择要申请的优惠券')
        this.$forceUpdate()
        return false
      }
      this.$axios.post(`${this.baseURL}/coo/applyVoucher`, JSON.stringify(applyArr)).then(data => {
        if(data.code==='1'){
          this.getGroupCoupon()
        }
      })
    },
    getCompanyLessonList() {
      this.$axios.post(`${this.baseURLApp}/companyLesson/list`, this.param).then(res => {
      // this.$axios.post(`${this.baseURLApp}/companyLesson/manageList`, this.param).then(res => {
        // console.log(res)
        this.lessonList = res.data
        this.lessonList.map(item => {
          item.courseMainimage = item.courseMainimage?item.courseMainimage.indexOf('http') >= 0? item.courseMainimage : 'http://img.chaolu.com.cn' + item.courseMainimage: ''
          return item
        })
        if(this.lessonList.length <= 0) {
          this.active = 1
        }
      })
    },
    logOutTeam(){
      this.$dialog.confirm({
        message: '确认要退出团体？',
      }).then(async () => {
        // on confirm
        this.$axios.post(`${this.baseURL}/cooGeneral/delCooStaff`, Object.assign({}, this.param, {'staffId': this.param.id})).then(data => {
          this.$toast(data.msg)
          if(data.code==='1'){
            window.location.href = `${this.baseURLCopy}/appWeb/enterprisePromotion.html`
          }else {
            this.logOutShow = false
          }
        })
      })
    },
    getUserMessage(){
      //获取用户基本信息
      this.$axios.post(`${this.baseURLApp}/coo/myMessage`, this.param).then(data => {
        let {headImg,phone,name,employeeNo,identity,companyName,companyId,staffId,isAdmin,status,companyStatus,balanceStatus,adminName,depositStatus,limitList} = data.data
        this.param.id = staffId
        this.param.companyId = companyId
        this.headImg = headImg
        this.phone = phone
        this.name = name
        this.adminName = adminName
        this.changeName = name
        this.employeeNo = employeeNo //工号
        this.identity = identity===0?'待认证':identity===2?'认证失败':'已认证'
        this.companyName = companyName //公司名字
        this.companyId = companyId //团体id
        this.staffId = staffId //员工ID
        this.isAdmin = isAdmin
        this.status = status //个人账户状态
        this.companyStatus = companyStatus // 公司账户状态
        this.balanceStatus = balanceStatus //余额是否开启
        this.depositStatus = depositStatus === '0'?false:true
        let limitItem = limitList.shift()
        this.singleLimitFlag = limitItem.showFlag
        this.singleLimit =  limitItem.limit
        let limitShowFlag = 0, limitListCopy = []
        for(let i=0;i<limitList.length;i++){
          let item = limitList[i]
          if(item.limitName.indexOf('笔数') < 0){
            item.used = item.used?item.used.toFixed(2):'0.00'
            item.usedName = '已用额度'
            item.limitAllName = item.limitName + ' ¥'+ item.limit
          }else{
            item.usedName = '已用订单笔数'
            item.limitAllName =  item.limitName + ' ' + item.limit
          }
          if(item.showFlag){
            limitListCopy.push(item)
          }
        }

        this.limitList = limitListCopy
        this.getGroupCoupon()
        this.getCompanyLessonList()
      })
    },
  },
};
</script>

<style lang="less" scoped>
  .page-rooter-fff{
    background-color: #F5F5F5;
  }
  .container{
    display: flex;
    flex-direction: column;
  }
  .tabs-box{
    border-radius: 8px;
    padding:24px;
    background-color: white;
    width: 702px;
    box-sizing: border-box;
    margin: 24px auto 50px;
    .tabs-nav{
      display: flex;
      align-items: flex-end;
      div{
        padding-bottom: 20px;
        position: relative;
        font-weight: bold;
        color: #9AA1A9;
        font-size: 24px;
        margin-right: 32px;
        &.active{
          font-size: 28px;
          color: #242831;
          &:after{
            content: '';
            position: absolute;
            width: 48px;
            height: 6px;
            background: #267DFF;
            border-radius: 3px;
            left: calc(50% - 24px);
            bottom: 0;
          }
        }

      }

    }
    .course{
      padding: 32px 0 0;
      .course-item + .course-item{
        margin-top: 48px;
      }
      .course-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-l{
          display: flex;
          align-items: center;
          .course-name{
            line-height: 23px;
            font-size: 20px;
            color: #6C727A;
            div{
              margin-top: 8px;
              &:first-child{
                margin-top: 0;
                line-height: 33px;
              }
            }
            div:first-child{
              font-size: 28px;
              font-weight: bold;
              color: #242831;
              line-height: 33px;
            }
          }
          .course-pic{
            margin-right: 24px;
            img{
              width: 128px;
              height: 128px;
              object-fit: cover;
              border-radius: 8px;
              display: block;
            }
          }
        }
        .item-r{
          width: 104px;
          height: 60px;
          background: #FFDE00;
          border-radius: 8px;
          text-align: center;
          line-height: 60px;
          color: #242831;
          font-weight: bold;
          font-size: 24px;
          &.full{
            color: #FFFFFF;
            background: #242831;
          }
          &.end{
            color: #FFFFFF;
            background: #B2B4B7;
          }
        }
      }
    }
    .voucher{
      padding: 32px 0 0;
      .apply-btn{
        width: 654px;
        height: 88px;
        background: #FFDE00;
        border-radius: 8px;
        color: #242831;
        font-weight: bold;
        font-size: 28px;
        line-height: 88px;
        text-align: center;
        margin-top: 38px;
      }
      .voucher-item + .voucher-item{
        margin-top: 48px;
      }
      .voucher-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-l{
          display: flex;
          align-items: center;
        }
        .item-bg{
          width: 160px;
          height: 128px;
          border-radius: 8px;
          background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/voucherbg.png") no-repeat center center;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: BebasNeueBold;
          font-size: 64px;
          color: white;
          margin-right: 24px;
          span{
            font-size: 36px;
          }
        }
        .voucher-name{
          div:first-child{
            font-size: 28px;
            font-weight: bold;
            color: #242831;
          }
          div:last-child{
            margin-top: 16px;
            font-size: 20px;
            color: #6C727A;
            span{
              color: #242831;
            }
          }
        }
      }
    }
  }
  .top-box{
    flex-shrink: 0;
    width: 100%;
    background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/background.png") no-repeat center top;
    background-size: contain;
    padding:0 24px;
    box-sizing: border-box;
    .user-info{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 42px 0;
      .invite-btn{
        width: 152px;
        height: 60px;
        background: #242831;
        border-radius: 8px;
        font-size: 24px;
        font-weight: bold;
        line-height: 60px;
        text-align: center;
        color: white;
      }
      &>div:first-child{
        display: flex;
        align-items: center;
        img{
          width: 108px;
          height: 108px;
          border: 2px solid #fff;
          border-radius: 50%;
          margin-right: 26px;
        }
        div{
          color: white;
          .user-name{
            font-size: 28px;
            font-weight: bold;
            line-height: 33px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            span{
              height: 32px;
              background: rgba(255, 255, 255, 0.2);
              border-radius: 4px;
              border: 1px solid rgba(255, 255, 255, 0.5);
              font-size: 18px;
              line-height: 32px;
              font-weight: bold;
              padding: 0 8px;
              margin-left: 12px;
            }
          }
          .user-phone{
            font-size: 22px;
            line-height: 26px;
            span{
              font-size: 20px;
              color: rgba(255, 255, 255, 0.5);
              margin-right: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }
    .account{
      background: #FFFFFF;
      border-radius: 8px;
      padding: 20px 24px 10px;
      .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        div:first-child{
          font-weight: bold;
          font-size: 28px;
          color: #242831;
        }
        div:last-child{
          font-size: 20px;
          color: #267DFF;
        }
      }
      .account-info{
        padding: 62px 0 0;
        display: flex;
        //justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        &>div{
          min-width: 33.33%;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 52px;
          &>div:first-child{
            font-family: BebasNeueBold;
            color: #242831;
            font-weight: bold;
            line-height: 38px;
            margin-bottom: 16px;
            font-size: 56px;
            span{
              font-size: 32px;
            }
          }
          &>div:nth-child(2){
            font-size: 22px;
            line-height: 26px;
            color: #9AA1A9;
            margin-bottom: 22px;
          }
          &>div:last-child{
            font-size: 24px;
            line-height: 28px;
            color: #242831;
          }
        }
      }
    }
    .enterprise{
      margin-top: 24px;
      padding-bottom: 24px;
      .title{
        div:first-child{
          display: flex;
          align-items: center;
          span{
            height: 36px;
            background: #3C454E;
            border-radius: 4px;
            font-size: 22px;
            line-height: 36px;
            padding: 0 8px;
            color: #FFFFFF;
            font-weight: bold;
            margin-left: 16px;
          }
        }
        div:last-child{
          font-size: 22px;
          color: #6C727A;
        }
      }
      .enterprise-info{
        padding: 48px 0;
        text-align: center;
        div:first-child{
          line-height: 38px;
          color: #242831;
          font-size: 32px;
          span{

          }
        }
        div:last-child{
          color: #9AA1A9;
          line-height: 26px;
          font-size: 22px;
          margin-top: 8px;
        }
      }
      .enterprise-btn{
        height: 88px;
        background: rgba(38, 125, 255, 0.10000000149011612);
        border-radius: 8px;
        opacity: 1;
        line-height: 88px;
        text-align: center;
        color: #267DFF;
        font-size: 28px;
        font-weight: bold;
      }
    }
  }
  .freeze{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
      width: 192px;
      height: 192px;
    }
    p{
      margin-top: 40px;
      width: 264px;
      font-size: 24px;
      line-height: 32px;
      color: #9AA1A9;
      text-align: center;
    }
  }
  .height-btn{
    position: absolute;
    top: 0;
    right: 32px;
    display: flex;
    width: 13.33333vw;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    font-size: 72px;
  }
  // 修改 vant 步进器样式
  /deep/ .van-stepper__plus{
    background-color: rgba(38, 125, 255, 0.1)!important;
    width: 40px;
    height: 40px;
    &:before{
      height: 4px;
      background: #267DFF;
      border-radius: 2px;
    }
    &:after{
      width: 4px;
      background: #267DFF;
      border-radius: 2px;
    }
  }
  /deep/ .van-stepper__minus{
    color: #267DFF;
    background-color: #fff;
    border: 1px solid rgba(38, 125, 255, 0.1);
    width: 40px;
    height: 40px;
    &:before{
      height: 4px;
    }
  }
  /deep/ .van-stepper__input{
    color: black;
    font-size: 28px;
    font-weight: bold;
  }
  // # End #
</style>
