var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container iphonex-pb"},[_c('headeBar',{staticStyle:{"z-index":"9999","position":"relative"},attrs:{"title":"选择参与人","left-arrow":""},on:{"click-left":_vm.newAppBack,"setBarHeitht":_vm.setBarHeitht}}),_c('van-popup',{staticClass:"search-dialog",attrs:{"position":"top","transition":"fade"},model:{value:(_vm.searchShow),callback:function ($$v) {_vm.searchShow=$$v},expression:"searchShow"}},[_c('div',{staticClass:"search-columnbox",style:({
                paddingTop: _vm.barHeight + 'px',
                height: _vm.searchContentShow
                    ? 'calc(100vh - ' + _vm.keyboxHeight + 'px)'
                    : 'auto',
            })},[_c('div',{staticClass:"search row-start-center"},[_c('img',{attrs:{"src":"https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/search-icon.png","alt":""}}),_c('van-field',{ref:"sh",staticClass:"search-input f28",attrs:{"placeholder":"搜索"},on:{"input":_vm.searchChange},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('span',{staticClass:"f28 flex-none",staticStyle:{"color":"#267dff"},on:{"click":_vm.cancelSearch}},[_vm._v("确认")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchContentShow),expression:"searchContentShow"}],staticClass:"search-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchList.length > 0),expression:"searchList.length > 0"}],staticClass:"iphonex-pb"},[_c('div',{staticClass:"list"},_vm._l((_vm.searchList),function(item){return _c('li',{key:item.userId,staticClass:"staff-item row-between-center",on:{"click":function($event){return _vm.choose(item)}}},[_c('div',{staticClass:"left row-start-center"},[_c('span',{class:[
                                        'check-box',
                                        _vm.checkedList.includes(item.userId)
                                            ? 'checked'
                                            : '',
                                    ]}),_c('p',{staticClass:"name f28 ellipsis"},[_vm._v(" "+_vm._s(item.userName)+" ")]),(item.isChildren == '1')?_c('span',{staticClass:"ischild"},[_vm._v("亲子账户")]):_vm._e()]),_c('p',{staticClass:"phone f24"},[_vm._v(_vm._s(item.mobile))])])}),0)]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchList.length === 0),expression:"searchList.length === 0"}],staticClass:"f32 fw6 nofinal"},[_vm._v(" 搜索不到结果 ")])])])]),_c('div',{staticClass:"search row-start-center",on:{"click":_vm.tosearch}},[_c('img',{attrs:{"src":"https://chaolucoachwebstatic.chaolu.com.cn/static/img/myBusinessManagement/search-icon.png","alt":""}}),_c('p',{staticClass:"f28"},[_vm._v("搜索团队成员")]),_c('van-field',{staticClass:"hackfull",attrs:{"placeholder":""}})],1),_c('div',{staticClass:"user-content"},_vm._l((_vm.groupList),function(group){return _c('div',{key:group.roleId,staticClass:"group-item"},[_c('div',{staticClass:"f28 row-start-center group-title"},[_vm._v(" "+_vm._s(group.roleName)+" ")]),_c('ul',{staticClass:"group-box"},_vm._l((group.staffList),function(item,index){return _c('li',{key:index,staticClass:"staff-item row-between-center",on:{"click":function($event){return _vm.choose(item)}}},[_c('div',{staticClass:"left row-start-center"},[_c('span',{class:[
                                'check-box',
                                _vm.checkedList.includes(item.userId)
                                    ? 'checked'
                                    : '',
                            ]}),_c('p',{staticClass:"name f28 ellipsis"},[_vm._v(_vm._s(item.userName))]),(item.isChildren == '1')?_c('span',{staticClass:"ischild"},[_vm._v("亲子账户")]):_vm._e()]),_c('p',{staticClass:"phone f24"},[_vm._v(_vm._s(item.mobile))])])}),0)])}),0),_c('div',{staticClass:"submit-board iphonex-pb"},[_c('div',{staticClass:"submit-box"},[_c('div',{staticClass:"row-between-center"},[_c('p',{staticClass:"name f28 fw6"},[_vm._v(_vm._s(_vm.courseDetail.name))]),_c('p',{staticClass:"f24",staticStyle:{"color":"#267dff"}},[_vm._v(" 已选择 "),_c('span',{staticClass:"fw6"},[_vm._v(_vm._s(_vm.checkedList.length)+"/"+_vm._s(_vm.courseDetail.maxNum))])])]),_c('p',{staticClass:"p4 f20"},[_vm._v(" "+_vm._s(_vm.courseDetail.date)),(_vm.type == 'training')?_c('span',[_c('span',{staticStyle:{"margin":"0 8px 0 4px"}},[_vm._v("开营")]),_vm._v(" 共"+_vm._s(_vm.courseDetail.lessonNum)+"节")]):_vm._e()]),_c('p',{staticClass:"p4 f20"},[_vm._v(" "+_vm._s(_vm.courseDetail.teacher)+" "),(_vm.type == 'coach')?_c('span',{staticClass:"pt"},[_vm._v("PT"+_vm._s(_vm.courseDetail.pt))]):_vm._e(),_vm._v(" | "+_vm._s(_vm.courseDetail.venueName)+" ")]),_c('div',{staticClass:"iphonex-pb searchsubbox"},[_c('div',{staticClass:"searchsub",on:{"click":_vm.confirm}},[_vm._v(" 确认分配"),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkedList.length),expression:"checkedList.length"}]},[_vm._v("（"+_vm._s(_vm.checkedList.length)+"）")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }